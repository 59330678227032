<template>
    <bs-popup id="master-customer-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Master Customer</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Code</label>
                <bs-textbox disabled v-model="model.code" />
                <bs-input-error :errors="errorMessages" keyError="Code" />
                <button class="btn btn-sm btn-info" @click="getDetailCustomer"><i class="bi bi-arrow-down"></i>Get Data Customer</button>
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Name</label>
                <bs-textbox disabled v-model="model.name" />
                <bs-input-error :errors="errorMessages" keyError="Name" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">City</label>
                <bs-textbox disabled v-model="model.city" />
                <bs-input-error :errors="errorMessages" keyError="City" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Address</label>
                <bs-textbox disabled v-model="model.address" />
                <bs-input-error :errors="errorMessages" keyError="Address" />
            </div>
            
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MasterCustomerService from "@/services/master-customer-service.js";

export default {
    components: {

    },
    data: function() {
        return {
            id: 0,
            model: MasterCustomerService.default(),
            errorMessages: null,
            isChange: false
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (id !== undefined && id !== null && id !== 0) {
            this.id = id;
            this.loadData();
        }
    },
    methods: {
        async loadData() {
            if (this.id === undefined || this.id === null || this.id === 0) 
            {
                return;
            }

            const result = await MasterCustomerService.getObject(this.id);
            if (result.status) {
                this.errorMessages = result.errorMessages;
            }
            else {
                this.errorMessages = null;
            }

            this.model = result.data;
        },
        async getDetailCustomer() {
            let status = false;
            const result = await MasterCustomerService.getObjectDetail(this.model.code);

            status = result.status;
            if (status) {
                this.errorMessages = null;
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            this.model.name = result.data.name;
            this.model.city = result.data.city;
            this.model.address = result.data.address;
            this.isChange = true;
        },
        async onPopupClosed() {
            this.$router.push('/master-customer');
        },
        async close() {
            this.$refs.form.closePopup();
        },
        async saveData() 
        {
            if (this.isChange) {
                if (confirm(`Nomor customer sudah pernah terdaftar dan terdapat perubahan data, Apakah anda setuju untuk melakukan update ? `)) {
                    try {
                        this.$store.commit('progressCounterQueueIncrement');

                        let status = false;
                        if (this.id !== undefined && this.id !== null && this.id !== 0) {
                            const result = await MasterCustomerService.update(this.model, this.id);
                            status = result.status;
                            if (status) {
                                this.errorMessages = null;
                            }
                            else {
                                this.errorMessages = result.errorMessages;
                            }
                        }

                        if (!status) return;

                        this.$refs.form.closePopup();
                        this.$router.push(`/master-customer/detail/${this.id}`);
                    }
                    finally {
                        this.$store.commit('progressCounterQueueDecrement');
                    }
                }
            } else {
                try {
                    this.$store.commit('progressCounterQueueIncrement');

                    let status = false;
                    if (this.id !== undefined && this.id !== null && this.id !== 0) {
                        const result = await MasterCustomerService.update(this.model, this.id);
                        status = result.status;
                        if (status) {
                            this.errorMessages = null;
                        }
                        else {
                            this.errorMessages = result.errorMessages;
                        }
                    }

                    if (!status) return;

                    this.$refs.form.closePopup();
                    this.$router.push(`/master-customer/detail/${this.id}`);
                }
                finally {
                    this.$store.commit('progressCounterQueueDecrement');
                }
            }
        }
    }
}
</script>